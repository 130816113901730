/* glowing effect */

:root {
    /* font-family:  "Tilt Neon", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif; */

    --neon: hsl(230 100% 60%);
    --neon-bright: hsl(230 100% 90%);
    --neon-dark: hsl(230 100% 10%);

    --neon-text: hsl(317 100% 50%);
    --neon-text-bright: hsl(317 100% 90%);
    --neon-text-dark: hsl(317 100% 10%);

    --neon-text1: hsl(130, 100%, 50%);
    --neon-text1-bright: hsl(133, 100%, 90%);
    --neon-text1-dark: hsl(140, 100%, 10%);
}

.neon {
    display: block;
    padding: 0.5rem;
    width: 5rem;
    border: 1px white solid;
    border-radius: 4rem;
    box-shadow:
        0 0 1px 2px var(--neon-bright),
        0 0 1px 2px var(--neon-bright) inset,
        0px 0px 10px var(--neon),
        0px 0px 10px var(--neon) inset,
        0px 0px 20px var(--neon),
        0px 0px 20px var(--neon) inset,
        0px 0px 30px var(--neon),
        0px 0px 30px var(--neon) inset,
        /* 0px 0px 40px var(--neon), */ 0px 0px 40px var(--neon) inset,
        0px 0px 50px var(--neon) inset,
        0px 0px 300px var(--neon-bright) inset;
}

.neon .neon-text {
    display: block;
    margin: 0;
    color: white;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 3px;
    line-height: 1;
    z-index: 1;
    text-shadow:
        0 0 3px var(--neon-text-bright),
        0 0 3px var(--neon-text-bright),
        0px 0px 10px var(--neon-text),
        0px 0px 20px var(--neon-text),
        0px 0px 30px var(--neon-text),
        0px 0px 40px var(--neon-text),
        0px 0px 50px var(--neon-text),
        0px 0px 60px var(--neon-text),
        0px 0px 70px var(--neon-text),
        0px 0px 100px var(--neon-text),
        0px 0px 200px var(--neon-text),
        0px 0px 500px var(--neon-text);
}

.neon .neon-text1 {
    display: block;
    margin: 0;
    color: white;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 3px;
    line-height: 1;
    z-index: 1;
    text-shadow:
        0 0 3px var(--neon-text1-bright),
        0 0 3px var(--neon-text1-bright),
        0px 0px 10px var(--neon-text1),
        0px 0px 20px var(--neon-text1),
        0px 0px 30px var(--neon-text1),
        0px 0px 40px var(--neon-text1),
        0px 0px 50px var(--neon-text1),
        0px 0px 60px var(--neon-text1),
        0px 0px 70px var(--neon-text1),
        0px 0px 100px var(--neon-text1),
        0px 0px 200px var(--neon-text1),
        0px 0px 500px var(--neon-text1);
}
